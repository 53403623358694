import ApplicationController from './application_controller'
import Rails from '@rails/ujs'
import Idle from 'lib/idle'

const E = window.E = window.E || {}

// Connects to data-controller="chat"
export default class extends ApplicationController {

  static targets = ['messages', 'characterCount', 'newMessage']

  static values = {
    heartbeatUrl:      String,
    heartbeatInterval: { type: Number, default: 10000 }
  }

  connect() {
    E.chat = this

    this.playNotificationSounds = false

    this.heartbeat = this.heartbeat.bind(this)
    this.heartbeatInterval = setInterval(this.heartbeat, this.heartbeatIntervalValue)

    this.scrollToBottom()
  }

  disconnect() {
    this.playNotificationSounds = false

    if (this.heartbeatInterval) { clearInterval(this.heartbeatInterval) }
  }

  countCharacters() {
    if (this.hasCharacterCountTarget && this.hasNewMessageTarget) {
      this.characterCountTarget.dataset.content = this.newMessageTarget.value.length
    }
  }

  scrollToBottom() {
    if (this.hasMessagesTarget) {
      this.messagesTarget.scrollTo(0, this.messagesTarget.scrollHeight)
    }
  }

  clearInput() {
    if (this.hasNewMessageTarget) {
      this.newMessageTarget.value = ""
    }
  }

  heartbeat() {
    if (!Idle.status && this.hasHeartbeatUrlValue) {
      Rails.ajax({
        type: 'post',
        url:  this.heartbeatUrlValue
      })
    }
  }
}
