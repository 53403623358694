import ApplicationController from './application_controller'

// Connects to data-controller="notification"
export default class extends ApplicationController {

  static targets = [ 'audio' ]
  static values  = {
    audioUrl: { type: String, default: '/chat-notification.wav' }
  }

  get volume () {
    if (this.hasAudioTarget) {
      return this.audioTarget.volume
    } else {
      return this._volume || (this._volume = 1.0)
    }
  }

  set volume (newVolume) {
    if (this.hasAudioTarget) {
      this.audioTarget.volume = newVolume;
    } else {
      this._volume = newVolume
    }
  }

  connect() {
    window.xyz = this
    this.volume = 0.0
  }

  playInitial(event) {
    if (!this.playedInitial) {
      this.volume = 0.0
      this.ping()
      this.playedInitial = true
    }
  }

  ping() {
    if (this.playedInitial) {
      this.volume = 1.0
    }

    if (this.hasAudioTarget) {
      this.audioTarget.play()
    } else {
      let notification = new Audio(this.audioUrlValue)
      notification.volume = this.volume
      notification.play()
    }
  }
}
