const ACTIVE_EVENTS     = ['mousemove', 'mousedown', 'keydown', 'touchmove']
const VISIBILITY_EVENTS = ['visibilitychange', 'webkitvisibilitychange', 'mozvisibilitychange', 'msvisibilitychange']

const E = window.E = window.E || {}
E.idle = E.idle || {}

// Detect as idle after 30 seconds:
E.idle.timeout = 30000
E.idle.status  = null
E.idle.timer   = null

let idleTimeoutId

E.idle.detectAsIdle = function() {
  E.idle.status = true
}

E.idle.startTimer = function() {
  E.idle.status = false
  E.idle.timer = setTimeout(E.idle.detectAsIdle, E.idle.timeout )
}

E.idle.stopTimer = function() {
  E.idle.status = true

  if (E.idle.timer) { clearTimeout(E.idle.timer) }
}

E.idle.resetTimer = function() {
  E.idle.stopTimer()
  E.idle.startTimer()
}

E.idle.checkTimer = function() {
  if (document.hidden || document.webkitHidden || document.mozHidden || document.msHidden) {
    E.idle.stopTimer()
  } else {
    E.idle.resetTimer()
  }
}

E.idle.setup = function() {
  if (E.idle.status == null) {
    E.idle.status = false

    ACTIVE_EVENTS.forEach((event)     => document.addEventListener(event, E.idle.resetTimer))
    VISIBILITY_EVENTS.forEach((event) => document.addEventListener(event, E.idle.checkTimer))
  }
}

E.idle.setup()

export default E.idle